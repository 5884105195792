import React, { useState, useEffect } from 'react';
import { TextField, Button, List, ListItem, ListItemText, Container, Paper, CircularProgress } from '@mui/material';
import { OpenAI } from 'openai';
import ReactMarkdown from 'react-markdown';

function AssistantChat({ assistant_id }) {
  const [client, setClient] = useState(null);
  const [thread, setThread] = useState(null);
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [apiKey, setApiKey] = useState('');
  const [isApiKeyEntered, setIsApiKeyEntered] = useState(false);

  useEffect(() => {
    if (isApiKeyEntered) {
      const initializeClient = async () => {
        const newClient = new OpenAI({
          apiKey: apiKey,
          dangerouslyAllowBrowser: true
        }); // Setzen Sie hier Ihren API-Schlüssel ein
        const newThread = await newClient.beta.threads.create();
        setClient(newClient);
        setThread(newThread.id);
      };

      initializeClient();
    };
  }, [isApiKeyEntered, apiKey]);

  const handleApiKeyChange = (event) => {
    setApiKey(event.target.value);
  };

  const handleApiKeySubmit = () => {
    if (apiKey) {
      setIsApiKeyEntered(true);
    }
  };

  const handleInputChange = (event) => {
    setInput(event.target.value);
  };

  const handleInputKeyPress = (event) => {
    if (event.key === 'Enter') {
      sendMessage();
    }
  };

  const sendMessage = async () => {
    if (!thread) {
      console.error('Thread-ID ist nicht gesetzt');
      return;
    }
    setIsLoading(true);
    const userMessage = { role: 'user', content: input };
    setMessages([...messages, userMessage]);
    setInput('');

    await client.beta.threads.messages.create(thread, {
      role: 'user',
      content: input,
    });

    const run = await client.beta.threads.runs.create(thread, {
      assistant_id: assistant_id,
    });


    // Wenn der `run` noch nicht abgeschlossen ist, können Sie eine Funktion zum Abrufen der `run`-Details erneut aufrufen
    // Sie können eine Verzögerung hinzufügen, um sicherzustellen, dass Sie nicht ständig die API aufrufen
    setTimeout(() => retrieveRun(run.id), 1000);
  };

  const retrieveRun = async (runId) => {
    const run = await client.beta.threads.runs.retrieve(thread, runId);

    if (run.status === 'completed') {
      //console.log(run);
      const thread_messages = await client.beta.threads.messages.list(
        thread,
        {
          limit: 1,
        }
      );
      const content = thread_messages.data[0].content;
      const aiMessage = { role: 'assistant', content: content[0].text.value };
      const userMessage = { role: 'user', content: input };
      //console.log(aiMessage);
      setMessages([...messages, userMessage, aiMessage]);
      setInput('');
      setIsLoading(false);
    } else {
      setTimeout(() => retrieveRun(runId), 1000);
    }
  };

  const getMessageLabel = (role) => {
    return role === 'user' ? 'Ich' : 'GMA-Assi';
  };

  const renderMessageContent = (content) => {
    return <ReactMarkdown
      components={{
        // Definiert, wie Listen gerendert werden, um die Einbettung in <p>-Tags zu vermeiden
        ul: ({ node, ...props }) => <ul style={{ paddingInlineStart: '20px' }} {...props} />,
        li: ({ node, ...props }) => <li {...props} />,
        p: ({ node, ...props }) => <p {...props} />
      }}
    >
      {content}
    </ReactMarkdown>;
  };

  if (!isApiKeyEntered) {
    return (
      <Container>
        <Paper style={{ padding: '20px', marginTop: '20px' }}>
          <TextField
            fullWidth
            label="API-Key"
            value={apiKey}
            onChange={handleApiKeyChange}
            variant="outlined"
            margin="normal"
          />
          <Button variant="contained" color="primary" onClick={handleApiKeySubmit}>
            API-Key bestätigen
          </Button>
        </Paper>
      </Container>
    );
  }

  return (
    <Container>
      <Paper style={{ padding: '20px', marginTop: '20px' }}>
        <List>
          {messages.map((message, index) => (
            <ListItem key={index}>
              <ListItemText className={message.role}
                primary={getMessageLabel(message.role)}
                secondary={renderMessageContent(message.content)}
              />
            </ListItem>
          ))}
        </List>
        {isLoading && <CircularProgress />}
        <TextField
          fullWidth
          label="Nachricht"
          value={input}
          onChange={handleInputChange}
          onKeyPress={handleInputKeyPress}
          variant="outlined"
          margin="normal"
        />
        <Button variant="contained" color="primary" onClick={sendMessage} disabled={isLoading}>
          Senden
        </Button>
      </Paper>
    </Container>
  );
}

export default AssistantChat;

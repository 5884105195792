import logo from './logo.svg';
import './App.css';
import AssistantChat from './AssistantChat';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>GMA-Workshop-GPT</h1>
      </header>
      <div className="App-chat">
        <AssistantChat assistant_id="asst_KHmGfnesGb4WuxPqVVmxCbGp" />
      </div>
    </div>
  );
}

export default App;

